/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
import { registerLinkResolver } from "gatsby-source-prismic-graphql"

// import { NavStateProvider } from "./src/hooks/NavState"
// import { WindowSizeProvider } from "./src/hooks/WindowSize"
import { linkResolver } from "./src/util/linkResolver"

registerLinkResolver(linkResolver)

// export const wrapRootElement = ({ element }) => {
//   return (
//     <WindowSizeProvider>
//       <NavStateProvider>{element}</NavStateProvider>
//     </WindowSizeProvider>
//   )
// }

// Intersection Observer polyfill for https://www.gatsbyjs.org/packages/gatsby-background-image/
export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}
