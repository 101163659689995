import React from "react"

import { useRouteOverlay } from "../hooks/RouteOverlay"
import { AnimatePresence, motion } from "framer-motion"
import "./CardOverlay.scss"

const CardOverlay: React.FC = () => {
  const routeOverlay = useRouteOverlay()

  return (
    <AnimatePresence>
      {routeOverlay.visible && (
        <motion.div
          className="route-overlay"
          animate={{ y: "0%" }}
          exit={{ y: "100%" }}
          initial={{ y: "100%" }}
          transition={{ ease: "easeInOut" }}
        >
          {routeOverlay.component}
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default CardOverlay
