import React from "react"

export type TreeTypes = "collection" | "project"
export type NavTreeState = {
  [key in TreeTypes]: {
    [key: string]: string
  }
}
type ReducerAction = {
  type: TreeTypes
  items: {
    [key: string]: string
  }
}
type SetNavTreeState = (action: ReducerAction) => void

const NavTreeStateContext = React.createContext<NavTreeState | undefined>(
  undefined
)

const NavTreeSetContext = React.createContext<SetNavTreeState | undefined>(
  undefined
)

const reducer = (state: NavTreeState, action: ReducerAction) => {
  return {
    ...state,
    [action.type]: action.items,
  }
}

const NavTreeProvider: React.FC = ({ children }) => {
  const initial = {
    collection: {},
    project: {},
  }
  const [navTree, setNavTree] = React.useReducer(reducer, initial)
  return (
    <NavTreeStateContext.Provider value={navTree}>
      <NavTreeSetContext.Provider value={setNavTree}>
        {children}
      </NavTreeSetContext.Provider>
    </NavTreeStateContext.Provider>
  )
}

const useNavTree = () => {
  const context = React.useContext(NavTreeStateContext)
  if (context === undefined) {
    throw new Error(
      "useNavTree must be used within a NavTreeStateContext Provider"
    )
  }
  return context
}

const useNavTreeSet = () => {
  const context = React.useContext(NavTreeSetContext)
  if (context === undefined) {
    throw new Error(
      "useNavTreeSet must be used within a NavTreeSetContext Provider"
    )
  }
  return context
}

export { NavTreeProvider, useNavTree, useNavTreeSet }
