import React from "react"
import { motion, AnimatePresence } from "framer-motion"

import Logo from "../assets/images/logo.svg"
import "./Header.scss"
import {
  NavStates,
  useNavSet,
  useNavState,
  useNavActionState,
  NavIconState,
} from "../hooks/NavState"
import {
  useWindowSetSize,
  useWindowSize,
  isBreakpointLess,
  isBreakpointMore,
} from "../hooks/WindowSize"
import TransitionLink from "./TransitionLink"
import NavBars from "./NavBars"

let resizeDebounce = -1

const Header: React.FC = () => {
  const setNavState = useNavSet()
  const navState = useNavState()
  const setWindowSize = useWindowSetSize()
  const windowSize = useWindowSize()
  const navActionState = useNavActionState()

  // watch for screen sizes and update the state
  React.useEffect(() => {
    const handleScreenChange = () => {
      // use a timeout to debounce so that it's not always updating
      clearTimeout(resizeDebounce)
      resizeDebounce = window.setTimeout(() => {
        setWindowSize(window.innerWidth)
      }, 100)
    }

    handleScreenChange()

    window.addEventListener("resize", handleScreenChange)

    return function cleanup() {
      window.removeEventListener("resize", handleScreenChange)
    }
  }, [])

  const iconAction = (newState: NavStates) => () => {
    setNavState(newState)
  }

  let iconState: NavIconState = "open"
  let nextState: null | NavStates = null
  let targetRoute = ""
  if ("icon" in navActionState) {
    // if we have an icon defined, then use that one
    iconState = navActionState.icon
    targetRoute = navActionState.to
  } else if ("toggle" in navActionState) {
    // otherwise, if we have a toggle defined
    // then use the appropriate icon
    const currentState = navActionState.toggle.filter(
      toggle => toggle.state === navState
    )
    if (currentState.length) {
      iconState = currentState[0].icon
      nextState = currentState[0].to
    }
  }

  // if we are using the mobile view we don't need to animate the icon at all
  let headerVariants: { [key: string]: any } = {
    open: {
      width: "500px",
      transition: {
        delay: 0.2,
      },
    },
    expanded: {
      width: "66%",
    },
    closed: {
      width: "30px",
    },
    compact: {
      width: "30px",
    },
  }

  // ensure we have 50% on larger screens
  if (isBreakpointMore("xl", windowSize)) headerVariants.expanded.width = "50%"
  if (isBreakpointLess("lg", windowSize)) {
    headerVariants = {
      open: {},
      expanded: {},
      closed: {},
      compact: {},
    }
  }

  // we only want to show the logo if the nav is open or if we're on mobile
  const showLogo =
    navState === "open" ||
    navState === "expanded" ||
    isBreakpointLess("lg", windowSize)

  return (
    <motion.header
      className={`header level--3 header--nav-state-${navState}`}
      variants={headerVariants}
      animate={navState}
      key={isBreakpointMore("lg", windowSize) ? "d-header" : "m-header"}
    >
      <AnimatePresence>
        {showLogo && (
          <motion.div animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <TransitionLink to="/" className="header__logo-link">
              <Logo className="header__logo" />
            </TransitionLink>
          </motion.div>
        )}
      </AnimatePresence>
      <NavBars
        to={targetRoute}
        iconState={iconState}
        action={nextState ? iconAction(nextState) : undefined}
      />
    </motion.header>
  )
}

export default Header
