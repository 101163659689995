import React from "react"

import { isBrowser } from "../util"

type WindowSize = number
type SetWindowSize = (s: WindowSize) => void
type Breakpoints = "sm" | "md" | "lg" | "xl" | "xxl"
type BreakpointsMap = {
  [key in Breakpoints]: number
}

const WindowSizeContext = React.createContext<WindowSize>(0)
const WindowSetContext = React.createContext<SetWindowSize | undefined>(
  undefined
)

export const breakpoints: BreakpointsMap = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1920,
}

type Props = { children: React.ReactNode }
function WindowSizeProvider({ children }: Props) {
  const [navSize, setWindowSize] = React.useState<WindowSize>(0)
  return (
    <WindowSizeContext.Provider value={navSize}>
      <WindowSetContext.Provider value={setWindowSize}>
        {children}
      </WindowSetContext.Provider>
    </WindowSizeContext.Provider>
  )
}

function useWindowSize() {
  const context = React.useContext(WindowSizeContext)
  if (context === undefined) {
    throw new Error(
      "useWindowSize must be used within a WindowSizeContext Provider"
    )
  }
  return context
}

function useWindowSetSize() {
  const context = React.useContext(WindowSetContext)
  if (context === undefined) {
    throw new Error(
      "useWindowSet must be used within a WindowSetContext Provider"
    )
  }
  return context
}

export function isBreakpointMore(breakpoint: Breakpoints, value: number) {
  return value >= breakpoints[breakpoint]
}

export function isBreakpointLess(breakpoint: Breakpoints, value: number) {
  return value < breakpoints[breakpoint]
}

export { WindowSizeProvider, useWindowSetSize, useWindowSize }
