import React from "react"
import GatsbyTransitionLink from "gatsby-plugin-transition-link"

interface TransitionLinkProps {
  to: string
  activeClassName?: string
  className?: string
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
  entry?: object
  exit?: object
  preventScrollJump?: boolean
}
const TransitionLink: React.FC<TransitionLinkProps> = ({
  children,
  to,
  entry = {},
  exit = {},
  ...rest
}) => {
  return (
    <GatsbyTransitionLink
      exit={{ length: 0.2, ...exit }}
      entry={{ delay: 0.2, ...entry }}
      to={to}
      {...rest}
    >
      {children}
    </GatsbyTransitionLink>
  )
}

export default TransitionLink
