import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { RichText } from "prismic-reactjs"

import "./Copyright.scss"
import { PrismicQuery, PrismicEdges } from "../types/prismic"

const seoQuery = graphql`
  query {
    prismic {
      allSite_configs {
        edges {
          node {
            footer_text
          }
        }
      }
    }
  }
`

type PrismicData = {
  footer_text: string
}

type AllSiteConfigs = {
  allSite_configs: PrismicEdges<PrismicData>
}

const CopyrightComponent: React.FC<PrismicQuery<AllSiteConfigs>> = ({
  data: prismicData,
}) => {
  const data = prismicData.prismic.allSite_configs.edges?.[0]?.node || {}

  return (
    <div className="body--extra-small copyright">
      {RichText.render(data.footer_text)}
    </div>
  )
}

const Copyright: React.FC = () => (
  <StaticQuery
    query={seoQuery}
    render={data => <CopyrightComponent data={data} />}
  />
)

export default Copyright
