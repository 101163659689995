module.exports = [{
      plugin: require('../plugins/local-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"boss-construction","path":"/preview","previews":false,"pages":[{"type":"Project","match":"/project/:uid","path":"/project","component":"/mnt/c/boss-construction/src/components/ProjectPage.tsx"},{"type":"Page","match":"/:uid","path":"/:uid","component":"/mnt/c/boss-construction/src/components/Pages.tsx"},{"type":"Project_collection_page","match":"/:uid","path":"/:uid","component":"/mnt/c/boss-construction/src/components/ProjectCollectionPages.tsx"},{"type":"Project_collection","match":"/:uid","path":"/:uid","component":"/mnt/c/boss-construction/src/components/ProjectCollection.tsx"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/mnt/c/boss-construction/src/components/Layout.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"boss-construction","short_name":"starter","start_url":"/","background_color":"#00B140","theme_color":"#00B140","display":"minimal-ui","icon":"src/images/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-102082159-1","head":false,"anonymize":true},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    }]
