import React from "react"
import { AnimatePresence, motion } from "framer-motion"

import TransitionLink from "./TransitionLink"
import "./Nav.scss"
import Copyright from "./Copyright"
import { useNavState } from "../hooks/NavState"
import LbpLogo from "../assets/logos/lbp.svg"
import MbLogo from "../assets/logos/mb.svg"
import SiteSafeLogo from "../assets/logos/sitesafe.svg"

const links: { to: string; text: string }[] = [
  {
    to: "/commercial",
    text: "Commercial",
  },
  {
    to: "/residential",
    text: "Residential",
  },
  {
    to: "/maintenance",
    text: "Maintenance",
  },
  {
    to: "/hire",
    text: "Hire",
  },
  {
    to: "/about-boss-construction",
    text: "About",
  },
  {
    to: "/contact",
    text: "Contact",
  },
]

const Nav: React.FC = () => {
  const navState = useNavState()
  return (
    <AnimatePresence>
      {navState === "open" && (
        <motion.div
          className="nav level--2 theme-dark d--flex justify--space-between"
          animate={{ translateX: 0, opacity: 1, transition: { delay: 0.2 } }}
          exit={{ translateX: "-100%", opacity: 0 }}
          initial={{ translateX: "-100%", opacity: 0 }}
          transition={{ ease: "easeIn" }}
        >
          <div className="nav__items">
            <ul className="nav__items-list heading--4">
              {links.map(link => (
                <li className="nav-item" key={link.to}>
                  <TransitionLink
                    to={link.to}
                    activeClassName="nav-item__link--active"
                  >
                    {link.text}
                  </TransitionLink>
                </li>
              ))}
            </ul>
          </div>
          <div className="nav__footer">
            <div className="nav__logos">
              <LbpLogo />
              <MbLogo />
              <SiteSafeLogo />
            </div>
            <Copyright />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Nav
