import React from "react"

import { TransitionState } from "../components/ITransition"

export type NavStates = "expanded" | "closed" | "compact" | "open"
export type NavIconState = "close" | "back" | "open"
type SetNavState = (s: NavStates) => void
type NavActionState = {
  to: string // the next route to move to
  icon: NavIconState // the icon to show
}

type NavActionToggle = {
  icon: NavIconState
  state: NavStates
  to: NavStates
}

type NavNextActionState = {
  toggle: [NavActionToggle, NavActionToggle]
}

export type NavActionStateTypes = NavActionState | NavNextActionState
type SetActionState = (s: NavActionStateTypes) => void

const NavStateContext = React.createContext<NavStates>("closed")
const NavSetContext = React.createContext<SetNavState | undefined>(undefined)
const NavActionContext = React.createContext<
  NavActionState | NavNextActionState | undefined
>(undefined)
const NavSetActionContext = React.createContext<SetActionState | undefined>(
  undefined
)

type Props = { children: React.ReactNode }
function NavStateProvider({ children }: Props) {
  const [navState, setNavState] = React.useState<NavStates>("closed")
  const [actionState, setActionState] = React.useState<
    NavActionState | NavNextActionState
  >({ to: "/", icon: "open" })
  return (
    <NavActionContext.Provider value={actionState}>
      <NavSetActionContext.Provider value={setActionState}>
        <NavStateContext.Provider value={navState}>
          <NavSetContext.Provider value={setNavState}>
            {children}
          </NavSetContext.Provider>
        </NavStateContext.Provider>
      </NavSetActionContext.Provider>
    </NavActionContext.Provider>
  )
}

function useNavState() {
  const context = React.useContext(NavStateContext)
  if (context === undefined) {
    throw new Error(
      "useNavState must be used within a NavStateContext Provider"
    )
  }
  return context
}

function useNavSet() {
  const context = React.useContext(NavSetContext)
  if (context === undefined) {
    throw new Error("useNavSet must be used within a NavSetContext Provider")
  }
  return context
}

function useSetNavAction() {
  const context = React.useContext(NavSetActionContext)
  if (context === undefined) {
    throw new Error(
      "useSetNavAction must be used within a NavSetActionContext Provider"
    )
  }
  return context
}

function useNavActionState() {
  const context = React.useContext(NavActionContext)
  if (context === undefined) {
    throw new Error(
      "useNavActionState must be used within a NavActionContext Provider"
    )
  }
  return context
}

type FunctionOrType<T> = () => T

function useSetRouteNavState(
  state: NavStates | FunctionOrType<NavStates>,
  transitionState: TransitionState,
  actionState:
    | NavActionState
    | NavNextActionState
    | FunctionOrType<NavActionState | NavNextActionState>,
  windowSize?: number
) {
  const setNavState = useNavSet()
  const setNavActionState = useSetNavAction()
  React.useEffect(() => {
    if (transitionState === "entered" || transitionState === "entering") {
      const nextState = typeof state === "function" ? state() : state
      const nextActionState =
        typeof actionState === "function" ? actionState() : actionState
      setNavState(nextState)
      setNavActionState(nextActionState)
    }
  }, [transitionState, windowSize])
}

export {
  NavStateProvider,
  useNavSet,
  useNavState,
  useSetRouteNavState,
  useSetNavAction,
  useNavActionState,
}
