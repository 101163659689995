import React from "react"

type RouteOverlayState = {
  visible: boolean
  component: React.ReactNode | null
}
const RouteOverlayContext = React.createContext<RouteOverlayState | undefined>(
  undefined
)

type ActionTypes = "show" | "hide" | "setComponent"
type ReducerAction = {
  action: ActionTypes
  component?: React.ReactNode
}
type SetRouteOverlay = (action: ReducerAction) => void
const SetRouteOverlayContext = React.createContext<SetRouteOverlay | undefined>(
  undefined
)

// create the reducer
const reducer = (state: RouteOverlayState, action: ReducerAction) => {
  switch (action.action) {
    case "show":
      return {
        ...state,
        visible: true,
      }
    case "hide":
      return {
        ...state,
        visible: false,
      }
    case "setComponent":
      return {
        ...state,
        visible: !!action.component,
        component: action.component || null,
      }
    default:
      throw new Error("Invalid action")
  }
}

const RouteOverlayProvider: React.FC = ({ children }) => {
  const initialState = {
    visible: false,
    component: null,
  }
  const [routeOverlay, setRouteOverlay] = React.useReducer(
    reducer,
    initialState
  )
  return (
    <RouteOverlayContext.Provider value={routeOverlay}>
      <SetRouteOverlayContext.Provider value={setRouteOverlay}>
        {children}
      </SetRouteOverlayContext.Provider>
    </RouteOverlayContext.Provider>
  )
}

const useRouteOverlay = () => {
  const context = React.useContext(RouteOverlayContext)
  if (context === undefined) {
    throw new Error("useRouteOverlay must be used within a provider")
  }
  return context
}

const useSetRouteOverlay = () => {
  const context = React.useContext(SetRouteOverlayContext)
  if (context === undefined) {
    throw new Error("useSetRouteOverlay must be used within a provider")
  }
  return context
}

export { RouteOverlayProvider, useRouteOverlay, useSetRouteOverlay }
