export const linkResolver = (doc: any) => {
  console.log(doc)
  switch (doc.type) {
    case "project": {
      return `/project/${doc.uid}`
    }
    case "hire_selection_page": {
      return "/hire/select"
    }
    case "contact_page": {
      return "/contact"
    }
    case "project_collection":
    case "project_type":
    case "page": {
      return `/${doc.uid}`
    }
    default: {
      return "/"
    }
  }
}
