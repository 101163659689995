import React from "react"
import { useTriggerTransition } from "gatsby-plugin-transition-link"

import { NavIconState } from "../hooks/NavState"
import "./NavBars.scss"

type NavBarsProps = {
  iconState: NavIconState
  action?(): void
  to?: string
}

// this determines whether or not we should run the handler or continue with navigation
type ClickProps = {
  to?(e: any): void
  action?(): void
}

const onClick = ({ to, action }: ClickProps) => (
  e: React.MouseEvent<HTMLAnchorElement>
) => {
  e.preventDefault()
  if (typeof action === "function") {
    action()
    return
  }
  if (typeof to === "function") {
    to(e)
    return
  }
}

const NavBars: React.FC<NavBarsProps> = ({ iconState, action, to }) => {
  const triggerTransition = useTriggerTransition({
    to: to,
  })
  return (
    <a
      className={`nav__toggle nav__toggle--${iconState}`}
      onClick={onClick({ to: triggerTransition, action })}
    >
      <div className="bar" />
      <div className="bar" />
      <div className="bar" />
    </a>
  )
}

export default NavBars
