// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---plugins-local-source-prismic-graphql-components-preview-page-js": () => import("./../plugins/local-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---plugins-local-source-prismic-graphql-components-preview-page-js" */),
  "component---src-components-project-collection-tsx": () => import("./../src/components/ProjectCollection.tsx" /* webpackChunkName: "component---src-components-project-collection-tsx" */),
  "component---src-components-pages-tsx": () => import("./../src/components/Pages.tsx" /* webpackChunkName: "component---src-components-pages-tsx" */),
  "component---src-components-project-collection-pages-tsx": () => import("./../src/components/ProjectCollectionPages.tsx" /* webpackChunkName: "component---src-components-project-collection-pages-tsx" */),
  "component---src-components-project-page-tsx": () => import("./../src/components/ProjectPage.tsx" /* webpackChunkName: "component---src-components-project-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-hire-select-tsx": () => import("./../src/pages/hire/select.tsx" /* webpackChunkName: "component---src-pages-hire-select-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-protected-playground-tsx": () => import("./../src/pages/protected/playground.tsx" /* webpackChunkName: "component---src-pages-protected-playground-tsx" */)
}

