import React from "react"
import { graphql, StaticQuery } from "gatsby"

import "../assets/fonts/style.css"
import "../scss/styles/index.scss"
import "./Layout.scss"
import Nav from "./Nav"
import Header from "./Header"
import { NavStateProvider } from "../hooks/NavState"
import { WindowSizeProvider } from "../hooks/WindowSize"
import { NavTreeProvider, useNavTreeSet } from "../hooks/NavTreeState"
import { RouteOverlayProvider } from "../hooks/RouteOverlay"
import { PrismicQuery, PrismicEdges } from "../types/prismic"
import ProjectCollectionPage from "./ProjectCollectionPages"
import ProjectCollection from "./ProjectCollection"
import CardOverlay from "./CardOverlay"

const navTreeQuery = graphql`
  query NavTreeQuery {
    prismic {
      allProject_collection_pages {
        edges {
          node {
            project_collections {
              project_collection {
                ... on PRISMIC_Project_collection {
                  _meta {
                    uid
                  }
                  projects {
                    project {
                      ... on PRISMIC_Project {
                        _meta {
                          uid
                        }
                      }
                    }
                  }
                }
              }
            }
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`

const flatten = (prev: object, current: object): object => {
  return { ...prev, ...current }
}

type ProjectCollectionPagesEdge = {
  _meta: {
    uid: string
  }
  project_collections: {
    project_collection: {
      _meta: {
        uid: string
      }
      projects: {
        project: {
          _meta: {
            uid: string
          }
        }
      }[]
    }
  }[]
}
type NavTree = {
  allProject_collection_pages: PrismicEdges<ProjectCollectionPagesEdge>
}
const LayoutInner: React.FC<PrismicQuery<NavTree>> = ({ children, data }) => {
  const setNavTree = useNavTreeSet()

  // process the nav tree so that we end up mapping a child to it's parent
  const collectionParents = data.prismic.allProject_collection_pages.edges
    .map(projectCollectionPage => {
      return projectCollectionPage.node.project_collections
        .map(projectCollection => {
          return {
            [projectCollection.project_collection._meta.uid]:
              projectCollectionPage.node._meta.uid,
          }
        })
        .reduce(flatten, {})
    })
    .reduce(flatten, {})

  // only run this when the parents change
  React.useEffect(() => {
    setNavTree({
      type: "collection",
      items: collectionParents,
    })
  }, Object.keys(collectionParents))

  // do the same for projectParents
  const projectParents = data.prismic.allProject_collection_pages.edges
    .map(ProjectCollectionPage => {
      return ProjectCollectionPage.node.project_collections
        .map(projectCollection => {
          return projectCollection.project_collection.projects
            .filter(project => project.project !== null)
            .map(project => {
              return {
                [project.project._meta.uid]:
                  projectCollection.project_collection._meta.uid,
              }
            })
            .reduce(flatten, {})
        })
        .reduce(flatten, {})
    })
    .reduce(flatten, {})
  React.useEffect(() => {
    setNavTree({
      type: "project",
      items: projectParents,
    })
  }, Object.keys(projectParents))
  return (
    <div>
      <Header />
      <Nav />
      <div className="layout-container position--relative level--1">
        {children}
      </div>
      <CardOverlay />
    </div>
  )
}

const Layout: React.FC = ({ children }) => {
  return (
    <WindowSizeProvider>
      <NavStateProvider>
        <NavTreeProvider>
          <RouteOverlayProvider>
            <StaticQuery
              query={navTreeQuery}
              render={data => <LayoutInner data={data}>{children}</LayoutInner>}
            />
          </RouteOverlayProvider>
        </NavTreeProvider>
      </NavStateProvider>
    </WindowSizeProvider>
  )
}

export default Layout
